import type { NextPage } from 'next';
import Link from 'next/link';
import _ from 'lodash';
import Image from 'next/image';
import ModelsGridComponent from '../components/models/models-grid';
import { getStaticPropsNicho, NewsGridComponent } from 'ui';
import { data, News, Web } from 'data';
import { Brand, Cooches, getRandomBrands, getRandomItems, Model } from '../data/schema';

type Props = {
	randomBrands: Brand[];
	randomModels: Model[];
	news: News[];
	web: Web;
};

const Home: NextPage<Props> = ({ randomBrands, randomModels, news, web }: Props) => {
	const createCard = (brand: Brand) => {
		return (
			<Link
				href={`/marcas-de-coches/${brand.id}`}
				key={`brand-card-${brand.id}`}
				className="shadow-lg rounded-2xl w-64 m-4 p-4 py-6 bg-white">
				<div className="flex flex-col items-center justify-center">
					<div className="w-24 h-24 rounded-full flex p-1">
						<Image
							src={`/images/brands/${brand.id}.png`}
							className="object-contain"
							alt={`Cooches - Marcas de coches - Logo de ${brand.name}`}
							title={`Cooches - Marcas de coches - Logo de ${brand.name}`}
							width={88}
							height={88}
							layout="fixed"
						/>
					</div>
					<h2 className="text-gray-800 text-xl font-medium mb-4 mt-4">{brand.name}</h2>
					<h3 className="text-gray-400 text-center text-xs px-2">{brand.shortDescription}</h3>
				</div>
			</Link>
		);
	};

	const brands = randomBrands.map((brand) => {
		return createCard(brand);
	});

	return (
		<div className="flex flex-col">
			<section className="flex pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Últimas noticias
					</h2>
					<NewsGridComponent news={news} web={web} />
				</div>
			</section>
			<section className="pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Descubre alguna nueva marca
					</h2>
					<div className="flex flex-wrap justify-center">
						{randomBrands.map((brand: Brand) => {
							return createCard(brand);
						})}
					</div>
				</div>
			</section>
			<section className="pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Descubre algún nuevo modelo
					</h2>
					<div>
						<ModelsGridComponent models={randomModels} />
					</div>
				</div>
			</section>
		</div>
	);
};

export const getStaticProps = getStaticPropsNicho(async () => {
	const news = await data().news().getLastNews();

	const randomBrands = await getRandomItems<Cooches, Brand>(4, 'co_brands', '-id', [
		'*',
		{ brands: ['*'] }
	]);
	const randomModels = await getRandomItems<Cooches, Model>(5, 'co_models', '-id', [
		'*',
		{ brands: ['*'] }
	]);

	console.log(randomBrands);

	return {
		props: {
			news,
			randomBrands,
			randomModels,
			title: 'Marcas de coches'
		}
	};
});

export default Home;
