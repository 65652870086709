import React from 'react';
import ModelComponent from './model-card';
import { Brand, Model } from '../../data/schema';

type Props = {
	models: Model[];
	brand?: Brand;
};

export const ModelsGridComponent = ({ models, brand }: Props) => {
	return (
		<section className="text-gray-600 body-font">
			<div className="container px-5 py-5 mx-auto">
				<div className="flex flex-wrap">
					{models!.map((model: Model) => {
						return (
							<ModelComponent
								key={model.code}
								model={model}
								brand={(model.brand as Brand) || brand}
							/>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default ModelsGridComponent;
