import Link from 'next/link';
import { Brand, Model } from '../../data/schema';
import { cdn } from 'data';

type Props = {
	model: Model;
	brand: Brand;
};

const ModelCard = ({ model, brand }: Props) => {
	return (
		<div key={model.code} className="w-1/2 md:w-1/3 lg:w-1/5 p-4">
			<div className="h-full rounded-xl shadow-cla-blue overflow-hidden bg-white shadow-lg">
				{/* <Image
					src={underConstructionImage}
					className="lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100"
					alt="Cooches - Estamos trabajando en ello"
				/> */}
				<img
					className="lg:h-24 md:h-36 w-full object-cover object-center transition-all duration-400 hover:scale-120"
					src={cdn(model.image)}
					alt={`${brand!.name} - ${model.name}`}
					title={`${brand!.name} - ${model.name}`}
				/>
				{/* <Image
				
					src={model.image}
					className="lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100"
					alt="Cooches - Estamos trabajando en ello"
				/> */}
				<div className="p-6">
					<h3 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">
						<Link href={`/marcas-de-coches/${brand!.id}`}>{brand!.name}</Link>
					</h3>
					<h2 className="title-font text-lg font-medium text-gray-600 mb-3">{model.name}</h2>
				</div>
			</div>
		</div>
	);
};

export default ModelCard;
